import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createPerformanceTracker } from '@wix/communities-blog-client-common';
import type { AppData } from '../../../viewer.app';
import {
  createControllerId,
  createLogger,
} from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { createMonitoring } from '../../common/services/create-monitoring';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { AppStore } from '../../common/types';
import { RELATED_POSTS_WIDGET_BUNDLE_NAME } from '../constants/related-posts-widget';
import { createReduxStore } from './create-redux-store';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { RelatedPostsApi } from './related-posts-api';

const isProduction = process.env.NODE_ENV === 'production';

export const createRelatedPostsController: CreateControllerFn = ({
  controllerConfig,
  flowAPI,
  appData,
}) => {
  const { appParams, setProps, wixCodeApi } = controllerConfig;
  const { isSSR, isDebug } = getEnvironment(wixCodeApi);
  const { fedopsLogger } = createMonitoring(flowAPI);
  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);
  const bundleName = RELATED_POSTS_WIDGET_BUNDLE_NAME;
  const log = createLogger(isDebug, isProduction);

  log('createRelatedPostsController', {
    appParams,
    wixCodeApi,
    isSSR,
    language,
  });

  const controllerId = createControllerId();
  const perfTag = `${bundleName} ${controllerId}`;
  const perf = createPerformanceTracker(perfTag, { isDebug, isSSR });
  const pageReadyMarker = perf.trackStart(
    `${new Date().toISOString().slice(11)} pageReady`,
  );

  initLazyActions({ isSSR });

  let marker = perf.trackStart('createReduxStore', pageReadyMarker);
  const store: AppStore = createReduxStore({
    fedopsLogger,
    isSSR,
    language,
    bundleName: perfTag,
    controllerConfig,
    flowAPI,
  });
  perf.trackEnd(marker);

  const pageReady = async () => {
    const model = await (
      appData as AppData | undefined
    )?.fetchPostPageRenderModel();

    log('createRelatedPostsController.pageReady -> start');
    simulateControllerError(wixCodeApi, 'relalated-posts.pageReady');

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({
      wixCodeApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions({
      store,
    });
    perf.trackEnd(marker);

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          store,
          language,
          bundleName,
          controllerConfig,
          postId: model?.post.id,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(wixCodeApi, appParams, store);

    log('createRelatedPostsController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);
    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
    });

    refreshDataOnLogin({ wixCodeApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return {
    pageReady,
    exports: () => new RelatedPostsApi(store),
    updateConfig: (_$w, { style: { styleParams } }) => {
      store.dispatch(setAppSettings({ style: styleParams }));
    },
  };
};
