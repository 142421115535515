import {
  createAction,
  handleAggregatorResponse,
} from '@wix/communities-blog-client-common';
import { FeedPageThunkAction } from '../../../feed-page/types';
import { loadTranslations } from '../../services/translations-getter';

export const FETCH_TRANSLATIONS_REQUEST = 'posts/FETCH_TRANSLATIONS_REQUEST';
export const FETCH_TRANSLATIONS_SUCCESS = 'posts/FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAILURE = 'posts/FETCH_TRANSLATIONS_FAILURE';

export const fetchTranslationsRequest = createAction(
  FETCH_TRANSLATIONS_REQUEST,
);
export const fetchTranslationsSuccess = createAction(
  FETCH_TRANSLATIONS_SUCCESS,
);
export const fetchTranslationsFailure = createAction(
  FETCH_TRANSLATIONS_FAILURE,
);

function fetchTranslationsAction(
  language: string,
  translationsName = 'main',
): FeedPageThunkAction<Record<string, string>> {
  return (_dispatch, _getState, { appParams, request }) => {
    return loadTranslations({
      request,
      baseUrl: appParams.baseUrls.translationsBaseUrl,
      translationsName,
      language,
    });
  };
}

const createFetchTranslations =
  <T>(action: (...args: any[]) => FeedPageThunkAction<T>) =>
  (...args: Parameters<typeof action>): FeedPageThunkAction<T> => {
    return async (dispatch) => {
      dispatch(fetchTranslationsRequest());

      const translationsPromise = dispatch(action(...args));

      try {
        const body = await translationsPromise;
        dispatch(fetchTranslationsSuccess(body));
      } catch (error) {
        console.error(error);
        dispatch(fetchTranslationsFailure());
      }

      return translationsPromise;
    };
  };

export const fetchTranslations = createFetchTranslations(
  fetchTranslationsAction,
);
export const handleTranslationsResponse = createFetchTranslations(
  handleAggregatorResponse,
);
