import { AppData } from '../../viewer.app';
import model from './model';

interface DefaultPlugin {
  set resourceId(postId: string);
}

export default model.createController(({ $w, appData }) => ({
  pageReady: async () => {
    const data = await (appData as AppData).fetchPostPageRenderModel();
    (
      $w('#slotRelatedPosts') as $w.SlotsPlaceholder<DefaultPlugin>
    ).slot.resourceId = data.post.id;
  },
  exports: {},
}));
